<template>
    <v-card class="mt-5">
        <AppVCardTitle label="Contrôles visuels divers - Hors trou d’homme" />
        <AppCheckboxWithConformityForm v-model="value.floorChecks" label="Vérification du sol, affaissement, fissures, déformation, inclinaison *" :required="true"/>
        <AppCheckboxWithConformityForm v-model="value.tankConditions" label="Contrôle de l’état général de la cuve (défauts peinture, coulures, coups, rouille, usure) *" :required="true"/>
        <AppCheckboxWithConformityForm v-model="value.pipingChecking" label="Vérification de la tuyauterie visible *" :required="true"/>
        <AppCheckboxWithButton v-model="value.regulatoryPlates.checked" label="Vérification de la présence des plaques réglementaires *" image_url="/img/implementedForms/62b97ed722d02/regulatoryPlates.png" :required="true"/>
        <AppRadioYesNo v-if="value.regulatoryPlates.checked === true" v-model="value.regulatoryPlates.tippingIdentification" label="Dépotage - identification cuve" />
        <AppRadioYesNo v-if="value.regulatoryPlates.checked === true" v-model="value.regulatoryPlates.fourdataQRCode" label="QR Code télémétrie Four Data" />

        <v-card-text v-if="value.regulatoryPlates.checked && value.regulatoryPlates.fourdataQRCode === 'false'" class="mt-n2 pt-0">
            <p class="font-16 font-weight-bold">Merci de coller un QR code sur l'affiche et de l'associer au capteur</p>
        </v-card-text>
        <AppCheckboxWithButton v-if="value.regulatoryPlates.checked === true" v-model="value.regulatoryPlates.pastPoster"  label="Collage de l'affiche ainsi que le QR code dans le bon emplacement *" image_url="/img/implementedForms/62b97ed722d02/pastPoster.png" :required="true"/>
        <v-card-text v-if="value.regulatoryPlates.checked === true" class="mt-n2 pt-0">
            Prendre en photo le QR code*
            <AppImageInput v-model="value.regulatoryPlates.qrCodeImage" placeholder="Photo du QR code" :rules="[v => !!v]"/>
        </v-card-text>

        <AppRadioYesNo v-if="value.regulatoryPlates.checked === true" v-model="value.regulatoryPlates.isQrCodeWellAssociated" label="QR Code associé au bon numéro du capteur" />
        <v-card-text v-if="value.regulatoryPlates.checked === true && value.regulatoryPlates.isQrCodeWellAssociated === 'false'" class="mt-n2 pt-0">
            <a :href="installer_url" target="_blank"> Associer le QR code <v-icon size="small">mdi-open-in-new</v-icon></a>
        </v-card-text>
    </v-card>
</template>

<script>
import AppCheckboxWithConformityForm from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithConformityForm";
import AppCheckboxWithButton from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithButton";
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo";
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";
import AppImageInput from "@/components/App/ImageInput.vue";

export default {
    name: "VariousVisualControls",
    components: {
        AppImageInput,
        AppVCardTitle,
        AppCheckboxWithConformityForm,
        AppCheckboxWithButton,
        AppRadioYesNo,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        installer_url() {
            return process.env.VUE_APP_INSTALLER_APP_URL + 'qr_code';
        }
    }
};
</script>
