<template>
    <v-card class="mt-5">
        <div v-if="value.comment !== null && value.comment !== '' ">
            <AppVCardTitle label="Commentaire de la maintenance" />
            <v-card-text>
                <v-row class="pl-3 pr-3 mb-n4" justify="space-between">
                    <p class="mb-0 mr-4 multiple-lines" v-html="value.comment">{{value.comment}}</p>
                </v-row>
            </v-card-text>
            <v-divider class="ma-2"/>
        </div>
        <AppVCardTitle label="Plan de prévention" />
        <v-card-text>
            <v-row class="pl-3 pr-3 mb-n4" justify="space-between">
                <p class="mb-0 mr-4">Le client a un plan de prévention</p>
                <v-radio-group v-model="value.hasPreventionPlan" row class="ma-0 mt-n1">
                    <v-radio label="Oui" value="true"></v-radio>
                    <v-radio label="Non" value="false"></v-radio>
                </v-radio-group>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-file-input v-model="value.preventionPlan" v-if="value.hasPreventionPlan === 'true'" prepend-icon="" prepend-inner-icon="mdi-paperclip" class="mb-n4" placeholder="Ajouter le plan de prévention" ref="repport_input" accept=".pdf" filled />
        </v-card-text>
        <v-card-text class="pt-0 pb-0">
            <v-row v-if="value.hasPreventionPlan === 'false'" class="pr-3 mb-n8" justify="end">
                <v-btn link href="https://client.oleo100.com/view/pdf/220901%20Plan%20de%20Pr%C3%A9vention%20Oleo100.pdf" target="_blank" icon color="primary">
                    <v-icon>fa fa-file</v-icon>
                </v-btn>
            </v-row>
        </v-card-text>

        <v-card-text class="pt-0 fill-height">
            <v-checkbox class="mb-n4 w_70p" v-model="value.presenceOfTheCustomer" label="Présence du client" />
            <v-checkbox v-model="value.viewPreventionPlan" v-if="value.presenceOfTheCustomer" class="mb-n4 mt-n4 w_70p" label="Plan de prévention et rapport d'intervention lu et approuvé par le client*" :rules="[v => v]"/>
            <v-text-field v-model="value.clientName" v-if="value.presenceOfTheCustomer" label="Nom du client"></v-text-field>
            <AppSignature v-model="value.clientSignature" v-if="value.presenceOfTheCustomer" class="mt-4" style="height: auto" label="Signature du client" :saveEvent="saveEvent" />
        </v-card-text>

    </v-card>
</template>

<script>
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";
import AppSignature from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Signature";

export default {
    name: "PreventivePlan",
    components: {
        AppVCardTitle,
        AppSignature,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        saveEvent: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.w_70p {
    width: 70%;
}
</style>